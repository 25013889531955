import React, { useState, useEffect } from 'react';
import moment from 'moment';

import getMoon from './helpers/moon.js'

import './Moon.css';

function Moon(props) {
  const [moon, setMoon] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMoon(props.size, (moon, success) => {
      if(success) {
        setMoon(moon)
        setLoading(false)
        if (props.popup && props.moonLoaded) {
          props.moonLoaded();
        }
      }
    })
  })

  if (loading) {
    return <div className="moon-load" />
  }

  const today = new Date();
  const day = today.getDate();

  const thumbSvg = moon.phase[day].svg.replace(`<a xlink:href="http://www.icalendar37.net/lunar/app/" target="_blank">`, '').replace('</a>', '');

  return (
    <>
      { props.desktop &&
        <div className="moon">
          <div>{moment(today).format('dddd')}</div>
          <div>{moment(today).format('DD MMMM YYYY')}</div>
          <div className="moon-image" dangerouslySetInnerHTML={{ __html: moon.phase[day].svg }}></div>
          <div>{moon.phase[day].phaseName + " "+ Math.round(moon.phase[day].lighting) + "%"}</div>
        </div>
      }
      { props.thumbnail &&
        <div className="moon-mobile-menu" onClick={() => props.toggleMoonPopup(true)} dangerouslySetInnerHTML={{ __html: thumbSvg }}></div>
      }
      { props.popup &&
        <div>
          <h1 className="moon-popup-text">{moment(today).format('dddd')}</h1>
          <h1 className="moon-popup-text">{moment(today).format('DD MMMM YYYY')}</h1>
          <div className="moon-image" dangerouslySetInnerHTML={{ __html: moon.phase[day].svg }}></div>
          <h1 className="moon-popup-text">{moon.phase[day].phaseName + " "+ Math.round(moon.phase[day].lighting) + "%"}</h1>
        </div>
      }
    </>
  )
}

export default Moon;
