import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";

import './App.css';

const styles = ['post1', 'post2', 'post3', 'post4', 'post5', 'post6', 'post7']

function Post() {
  const [post, setPost] = useState([]);
  const [loading, setLoading] = useState(true);
  const [className, setClassName] = useState();

  const { postName } = useParams()

  useEffect(() => {
   window.scrollTo(0, 0);
   fetch('https://public-api.wordpress.com/rest/v1/sites/indexfinger771404303.wordpress.com/posts/slug:' + postName)
   .then((response) => response.json())
   .then((data) => {
      setPost(data)

      let size
      if (data.categories.hasOwnProperty('large')) {
        size = 'large'
      } else if (data.categories.hasOwnProperty('medium')) {
        size = 'medium'
      }
      setClassName(styles[Math.floor(Math.random() * styles.length)] + ' ' + size);

      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div className="loading">loading...</div>
  }



  const tags = Object.keys(post.tags)

  return (
    <div className={className}>
      <img src={post.featured_image} className="post-image" alt="" />
      <h1 className="post-title">{post.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: post.content }} />

      {tags.length > 0 && <div className="post-tags">
        Index:
        {tags.map((tag, j) => {
          return (
            <Link key={j} className="post-tags-tag" to={`/index/${tag}`}>{j+1 === tags.length? tag : tag + ', '}</Link>
          )
        })}
      </div>}
    </div>
  )

}

export default Post;
