import React, { useState, useEffect } from 'react';
import './App.css';

import Posts from './Posts';

function AllPosts() {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
   fetch('https://public-api.wordpress.com/rest/v1/sites/indexfinger771404303.wordpress.com/posts?page=' + page)
   .then((response) => response.json())
   .then((data) => {
      setPosts(data.posts)
      setTotalPages(Math.ceil(data.found / data.posts.length));
      setLoading(false)
    })
  }, [page]);

  const nextPage = page + 1;
  const previousPage = page - 1;

  if (loading) {
    return <div className="loading">loading...</div>
  }

  return (
    <div>
      <Posts posts={posts} />
      <div className='page-button-wrap'>
        {page > 1 && <div onClick={() => setPage(previousPage)} className='page-button'>Previous Page</div>}
        {page < totalPages && <div onClick={() => setPage(nextPage)} className='page-button'>Next Page</div>}
      </div>
    </div>
  )
}

export default AllPosts;
