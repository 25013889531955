import React from 'react';
import { Link } from "react-router-dom";

import './App.css';

const styles = ['post1', 'post2', 'post3', 'post4', 'post5', 'post6', 'post7']

function Posts (props) {

  return (
    <div>
      <div className="posts">
        {props.posts.map((result, i) => {
          let size
          if (result.categories.hasOwnProperty('large')) {
            size = 'large'
          } else if (result.categories.hasOwnProperty('medium')) {
            size = 'medium'
          }
          const tags = Object.keys(result.tags)
          return (
            <div key={i} className={styles[Math.floor(Math.random() * styles.length)] + ' ' + size}>
              <img src={result.featured_image} className="post-image" alt="" />
              <Link className="post-title-clickable" to={`/${result.slug}`}>{result.title}</Link>
              <div dangerouslySetInnerHTML={{ __html: result.content }} />
              {tags.length > 0 && <div className="post-tags">
                Index:
                {tags.map((tag, j) => {
                  return (
                    <Link key={j} className="post-tags-tag" to={`/index/${tag}`}>{j+1 === tags.length? tag : tag + ', '}</Link>
                  )
                })}
              </div>}
            </div>
          )
        })}
      </div>

    </div>
  )
}

export default Posts
