import React, { useState, useEffect } from 'react';
import { Link, Routes, Route } from "react-router-dom";

import './App.css';

import AllPosts from './AllPosts';
import IndexPosts from './IndexPosts';
import Moon from './Moon';
import Post from './Post';

function App() {
  const [tags, setTags] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [showMoonPopup, setShowMoonPopup] = useState(false);
  const [popupMoonLoading, setPopupMoonLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
   fetch('https://public-api.wordpress.com/rest/v1/sites/indexfinger771404303.wordpress.com/tags?number=1000')
   .then((response) => response.json())
   .then((data) => {
     const tags = []
     data.tags.forEach((tag) => {
         if (tag.post_count > 0) {
           tags.push(tag.slug)
         }
      })
      setTags(tags)
      setLoading(false)
    })
  }, []);

  function toggleMenu(toggle) {
    if (toggle === false) {
      document.body.style.overflow = 'initial';
      document.documentElement.style.overflow = 'initial';
    } else {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    }
    setShowMenu(toggle)
  }

  function toggleMoonPopup(toggle) {
    if (toggle === false) {
      document.body.style.overflow = 'initial';
      document.documentElement.style.overflow = 'initial';
    } else {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    }
    setShowMoonPopup(toggle)
  }

  if (loading) {
    return <div className="loading">loading...</div>
  }

  return (
    <div className="home">
      <div className="content">
        <div className="mobile-bar">
          <div className="mobile-moon-box">
            <Moon size={30} thumbnail toggleMoonPopup={(toggle) => toggleMoonPopup(toggle)}/>
          </div>
          <i className="fas fa-bars hamburger" onClick={() => toggleMenu(true)}></i>
        </div>
        <div className="topbar">
          <Moon size={70} desktop />
          <Link to="/" className="title">
            Index Finger
          </Link>
        </div>
        <div className="posts">
          <Routes>
            <Route path="/index/:index" element={<IndexPosts />} />
            <Route path="/:postName" element={<Post />} />
            <Route path="/" element={<AllPosts />} />
          </Routes>
        </div>
      </div>

      <div className="index">
        <h4>Index:</h4>
        {tags.map((tag, i) => {
          return (
            <div key={i}>
              <Link to={`/index/${tag}`} className="index-tag">{tag}</Link>
            </div>
          )
        })}
      </div>

      { showMenu &&
        <div className="mobile-menu">
          <div className="mobile-index">
            <div className="mobile-menu-header">
              <div className="mobile-index-title">Index:</div>
              <i className="fas fa-times mobile-menu-close" onClick={() => toggleMenu(false)}></i>
            </div>
            {tags.map((tag) => {
              return (
                <Link className="mobile-index-tag" to={`/index/${tag}`} onClick={() => toggleMenu(false)}>{tag}</Link>
              )
            })}
          </div>
        </div>
      }
      { showMoonPopup &&
        <div className="mobile-menu">
          <div  className="moon-popup">
            <div className="moon-popup-header">
              <i className="fas fa-times moon-popup-close" onClick={() => { toggleMoonPopup(false); setPopupMoonLoading(true)}}></i>
            </div>
            {popupMoonLoading && <div className="loading">loading...</div>}
            <Moon size={100} popup moonLoaded={() => setPopupMoonLoading(false)} />
          </div>
        </div>
      }
    </div>
  );
}

export default App;
